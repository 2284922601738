

function plugins(Vue){
  if(plugins.installed){
    return
  }
  Vue.component('backBox', ()=> import('@/components/back'))
  
}

export default plugins