// 根据环境引入不同配置 process.env.NODE_ENV
// const config = require('./env.' + process.env.VUE_APP_ENV)
// const config = require('./env.production')
// const config = require('./env.staging')

// 手动切换环境

import config from './env.production' //线上

// import config from './env.staging' //内网测试 

// import config from './env.staging1'//外网测试

// const config = import('./env.' + process.env.VUE_APP_ENV)
// const config = import('./env.' + process.env.VUE_APP_ENV)


// module.exports = config

export default config