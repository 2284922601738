/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-06-03 12:59:20
 * @LastEditTime: 2019-08-15 17:49:01
 * @LastEditors: Please set LastEditors
 */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import state from './state.js'
import mutations from './mutations.js'

Vue.use(Vuex);



const store = new Vuex.Store({
  state,
  mutations,
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    reducer(val) {
      //val是整个state
      return {
        // 只储存state中的userData
        userData: val.userData,
        worksType: val.worksType
      }
    }
  })]
});

export default store;
